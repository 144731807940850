<template>
  <div class="card">
    <Toast position="top-center" group="info" />
    <h5>Результаты DAO: {{ dataRequest.name }}</h5>
    <DataTable
      :value="eventsDaoList"
      data-key="id"
      :loading="loading"
      responsiveLayout="scroll"
      paginator
      lazy
      :rows="rows"
      :totalRecords="totalItems"
      @page="setPage"
    >
      <template #empty> Данных пока нет </template>
      <template #loading> Loading customers data. Please wait. </template>

      <Column header="Вариант ответа" field="element.name" />
      <Column header="Amount" field="amount" />
    </DataTable>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'ManagerDaoEvents',
  data() {
    return {
      eventsDaoList: [],
      dataRequest: {},
      page: 1,
      itemsPerPage: 30,
      rows: 30,
      totalItems: 10,
    }
  },
  async mounted() {
    this.getEventDaoList()
  },

  methods: {
    moment(data) {
      return moment(data)
    },
    setPage(event) {
      this.page = event.page + 1
      this.getEventDaoList()
    },

    async getEventDaoList() {
      const headers = {
        accept: 'application/ld+json',
        'Content-Type': 'application/ld+json',
      }
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        pagination: true,
        requestId: this.$route.params.id,
      }
      try {
        const respRequest = await this.axios.get(
          `api/dao_requests/${this.$route.params.id}`
        )
        this.dataRequest = respRequest.data
        const resp = await this.axios.get('api/dao_events', {
          headers,
          params,
        })
        this.eventsDaoList = resp.data['hydra:member']
        this.totalItems = resp.data['hydra:totalItems']
      } catch (e) {
        console.log(e)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error in api call',
          group: 'info',
          life: 3000,
        })
      }
    },
  },
}
</script>

<style scoped>
.distance {
  margin-top: 7px;
}
.distance_button {
  margin-top: 10px;
}
</style>
